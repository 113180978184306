import React, { useState } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ModalVideo from 'react-modal-video'
import { faBookOpen, faCheckCircle, faClock, faDownload, faFileAlt, faFilePdf, faGlobe, faMedal, faPlay, faShareAlt, faSignal, faUserClock, faVideo } from "@fortawesome/free-solid-svg-icons"
import { Button, AccordionBody, AccordionHeader, AccordionItem, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, TabContent, TabPane, UncontrolledAccordion, UncontrolledDropdown } from "reactstrap"
import classNames from "classnames"
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton, TelegramShareButton, EmailShareButton, TwitterIcon, FacebookIcon, LinkedinIcon, WhatsappIcon, TelegramIcon, EmailIcon } from "react-share"
// import ContactWidget from './ContactWidget'
import CourseCurriculum from "./CourseCurriculum"
import CourseInstructors from "./CourseInstructors"
import EnrollmentForm from "../components/EnrollmentForm"
import Banner from "./Banner"
import course from '../data/Course.json'
import brochurePdf from '../assets/pdf/tunnels-tunneling-course-brochure.pdf'
import registerPdf from '../assets/pdf/tunnels-tunneling-course-registration-form.pdf'

const CourseDetail = () => {
  const [activeTab, setActiveTab] = useState('course-pills-1');
  const [playVideo, setPlayVideo] = useState(false);

  const buyCourse = () => {
    let cart = {
        id: course.id,
        slug: course.slug,
        title: course.title,
        description: course.shortDescription,
        image: course.image,
        priceInternational: course.priceInternational,
        priceCurrency: course.currency,
        price: course.price,
        cartCurrency: course.currency,
        cartAmount: course.price,
        hsnRate: course.hsnRate,
        hsnCode: course.hsnCode,
        taxIncluded: course.taxIncluded,
        orderId: ""
    }
    if (course.priceOptions && course.priceOptions.length > 0) {
        cart.priceOptions = course.priceOptions;
        cart.price = 0;
        for (const price of cart.priceOptions) {
            if (price.isDefault && (price.amount > cart.price)) {
                cart.price = price.amount;
                cart.priceCurrency = price.currency;
                cart.cartAmount = price.amount;
                cart.cartCurrency = price.currency;
                cart.taxIncluded = price.taxIncluded;
            }
        }
    }
    if (typeof window !== 'undefined') {
      // console.log('CourseDetail->buyCourse->window', cart)
      window.localStorage.setItem('cart', JSON.stringify(cart));
    } else {
      // console.log('CourseDetail->buyCourse->window', window)
    }
    return cart
  }

  const toggleTab = tabId => {
    if (activeTab !== tabId) {
      setActiveTab(tabId)
    }
  }

  return (
    <Layout>
        <Banner />
        
        {/* ======================= Page content START */}
        <section className="pb-0 py-lg-2">
          <div className="container">
            <div className="row">
              {/* Main content START  */}
              <div className="col-lg-8">
                <div className="card shadow rounded-2 p-0">
                  {/* Tabs START  */}
                  <div className="card-header border-bottom px-4 py-3">
                    <Nav pills id="course-pills-tab" className="nav-tabs-line py-0">
                      {/* Tab item  */}
                      <NavItem className="me-2 me-sm-4" active={activeTab==="course-pills-1"}>
                        <NavLink className="mb-2 mb-md-0" id="course-pills-tab-1" tag="button" active={activeTab==="course-pills-1"} onClick={() => {toggleTab('course-pills-1')}}>Overview</NavLink>
                      </NavItem>
                      {/* Tab item  */}
                      <NavItem className="me-2 me-sm-4" active={activeTab==="course-pills-2"}>
                        <NavLink className=" mb-2 mb-md-0" id="course-pills-tab-2" tag="button" active={activeTab==="course-pills-2"} onClick={() => {toggleTab('course-pills-2')}}>Curriculum</NavLink>
                      </NavItem>
                      {/* Tab item  */}
                      <NavItem className="me-2 me-sm-4" active={activeTab==="course-pills-3"}>
                        <NavLink className="mb-2 mb-md-0" id="course-pills-tab-3"  tag="button" active={activeTab==="course-pills-3"} onClick={() => {toggleTab('course-pills-3')}}>Instructors</NavLink>
                      </NavItem>
                      {/* Tab item  */}
                      {/*<NavItem className="me-2 me-sm-4" active={activeTab==="course-pills-4"}>
                        <NavLink className="mb-2 mb-md-0" id="course-pills-tab-4"  tag="button" active={activeTab==="course-pills-4"} onClick={() => {toggleTab('course-pills-4')}}>Reviews</NavLink>
                      </NavItem>*/}
                      {/* Tab item  */}
                      <NavItem className="me-2 me-sm-4" active={activeTab==="course-pills-5"}>
                        <NavLink className="mb-2 mb-md-0" id="course-pills-tab-5"  tag="button" active={activeTab==="course-pills-5"} onClick={() => {toggleTab('course-pills-5')}}>FAQs </NavLink>
                      </NavItem>
                      <NavItem className="me-2 me-sm-4" active={activeTab==="course-pills-6"}>
                        <NavLink className="mb-2 mb-md-0" id="course-pills-tab-6"  tag="button" active={activeTab==="course-pills-6"} onClick={() => {toggleTab('course-pills-6')}}>Fee Structure </NavLink>
                      </NavItem>
                      <NavItem className="me-2 me-sm-4" active={activeTab==="course-pills-7"}>
                        <NavLink className="mb-2 mb-md-0" id="course-pills-tab-6"  tag="button" active={activeTab==="course-pills-7"} onClick={() => {toggleTab('course-pills-7')}}>Enroll </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                  {/* Tabs END  */}

                  {/* Tab contents START  */}
                  <div className="card-body p-4">
                    <TabContent className="pt-2" id="course-pills-tabContent" activeTab={activeTab}>
                      {/* Overview Content START  */}
                      <TabPane className={classNames("fade", {show: activeTab==="course-pills-1", active: activeTab==="course-pills-1" })} tabId="course-pills-1">
                        {/* Course detail START  */}
                        <h5 className="mb-3">Course Description</h5>
                        <p className="mb-3">Tunnelling is integral to infrastructure development and this online training program aims at imparting an in- depth knowledge pertaining to all aspects of tunnels and tunnelling pertaining to hydro and transport projects at a single platform.</p>
                        <p className="mb-3">Spanning the entire spectrum from planning to operations & maintenance stages, the program with 100 pre-recorded lectures ensures that each and every aspect like survey & investigation, design, and construction of a tunnel is addressed.</p>
                        <p className="mb-3">As a unique offering, the program comprises a set of 51 lectures in the Core Segment, addressing aspects of general interest, and allows the participants to choose from the remaining lectures in the Optional Segment depending upon their requirement. With the twice-a-week release of the lectures, interspersed with interactive sessions with the faculty, the training program is designed for a schedule spanning 25-weeks.</p>

                        <h5 className="mb-3">Who should attend this course </h5>
                        <p className="mb-3">This training program shall benefit professionals, engineers, geotechnical engineers and geo- scientists dealing with tunnelling projects. The program shall be of value to owners of the tunnelling projects while hiring services for planning, investigating, designing, constructing, and operating their projects. The participants of the program will be able to contribute and add value in the accelerated and economic development of tunnelling projects.</p>

                        <h5 className="mb-3">Contents</h5>
                        <p className="mb-3">The broad list of topics are listed in the curriculum section. It also gives a full glimpse of the program to the participant at the time of program announcement and helps him choose the lectures of his choice.</p>
                        
                        {/* List content  */}
                        <h5 className="mt-4">What you’ll learn</h5>
                        <p className="mb-3">Course comprising following Modules spanning the full spectrum of a tunnelling project from inception to O&M. These shall be divided in to 2 packages, viz. core and optional</p>
                        <ul className="list-group list-group-borderless mb-3">
                          <li className="list-group-item h6 fw-light d-flex mb-0"><FontAwesomeIcon icon={faCheckCircle} className="text-success me-2" />Introduction</li>
                          <li className="list-group-item h6 fw-light d-flex mb-0"><FontAwesomeIcon icon={faCheckCircle} className="text-success me-2" />Formulating a project</li>
                          <li className="list-group-item h6 fw-light d-flex mb-0"><FontAwesomeIcon icon={faCheckCircle} className="text-success me-2" />Survey &amp; investigation</li>
                          <li className="list-group-item h6 fw-light d-flex mb-0"><FontAwesomeIcon icon={faCheckCircle} className="text-success me-2" />Design</li>
                          <li className="list-group-item h6 fw-light d-flex mb-0"><FontAwesomeIcon icon={faCheckCircle} className="text-success me-2" />Tender and contract management</li>
                          <li className="list-group-item h6 fw-light d-flex mb-0"><FontAwesomeIcon icon={faCheckCircle} className="text-success me-2" />Tunnel construction</li>
                        </ul>
                        {/* Course detail END  */}

                      </TabPane>
                      {/* Overview Content END  */}

                      {/* Curriculum Content START  */}
                      <TabPane className={classNames("fade", {show: activeTab==="course-pills-2", active: activeTab==="course-pills-2" })} tabId="course-pills-2">
                        {/* Course accordion START  */}
                        <CourseCurriculum />
                        {/* Course accordion END  */}
                      </TabPane>
                      {/* Curriculum Content END  */}

                      {/* Instructors Content START  */}
                      <TabPane className={classNames("fade", {show: activeTab==="course-pills-3", active: activeTab==="course-pills-3" })} tabId="course-pills-3">
                        {/* Card START  */}
                        <div className="card mb-0 mb-md-4">
                          <CourseInstructors />
                        </div>
                        {/* Card END  */}
                      </TabPane>
                      {/* Instructors Content END  */}

                      {/* FAQ Content START  */}
                      <TabPane className={classNames("fade", {show: activeTab==="course-pills-5", active: activeTab==="course-pills-5" })} tabId="course-pills-5">
                        {/* Title  */}
                        <h5 className="mb-3">Frequently Asked Questions</h5>
                        {/* Accordion START  */}
                        <UncontrolledAccordion flush defaultOpen={`faq1`}>
                          {/* Item  */}
                          <AccordionItem>
                            <AccordionHeader targetId="faq1">
                                <span className="text-secondary fw-bold me-3">01</span>  
                                <span className="fw-bold">Who should join this course?</span> 
                            </AccordionHeader>
                            <AccordionBody accordionId="faq1">
                                This training program shall benefit professionals, engineers, geotechnical engineers and geo- scientists dealing with tunnelling projects. The program shall be of value to owners of the tunnelling projects while hiring services for planning, investigating, designing, constructing, and operating their projects. The participants of the program will be able to contribute and add value in the accelerated and economic development of tunnelling projects.
                            </AccordionBody>
                          </AccordionItem>
                          {/* Item  */}
                          <AccordionItem>
                            <AccordionHeader targetId="faq2">
                                <span className="text-secondary fw-bold me-3">02</span>  
                                <span className="fw-bold">How will the course be delivered?</span> 
                            </AccordionHeader>
                            <AccordionBody accordionId="faq2">
                                The course will be delivered through Learning Management System (LMS) where pre-recorded lectures, videos, presentation, reading material etc. will be uploaded enabling participants go through these at their own pace, within the stipulated time frame.
                            </AccordionBody>
                          </AccordionItem>
                          {/* Item  */}
                          <AccordionItem>
                            <AccordionHeader targetId="faq3">
                                <span className="text-secondary fw-bold me-3">03</span>  
                                <span className="fw-bold">What is the course schedule?</span> 
                            </AccordionHeader>
                            <AccordionBody accordionId="faq3">
                                The course is designed for a schedule spanning 25 weeks with two lectures released every Monday and Thursday of each week. The lectures are released for the core segment followed by the optional segement. Each lecture is of 20-25 minute duration.
                            </AccordionBody>
                          </AccordionItem>
                          {/* Item  */}
                          <AccordionItem>
                            <AccordionHeader targetId="faq4">
                                <span className="text-secondary fw-bold me-3">04</span>  
                                <span className="fw-bold">Will there be any live/interactive sessions?</span> 
                            </AccordionHeader>
                            <AccordionBody accordionId="faq4">
                                Interactive sessions with faculty shall be convened every four-week, wherein participants can directly interact with experts and get their concerns resolved. Preferred mode of receiving concerns and queries would remain through email, enabling development of a comprehensive Q&A.
                            </AccordionBody>
                          </AccordionItem>
                          {/* Item  */}
                          <AccordionItem>
                            <AccordionHeader targetId="faq5">
                                <span className="text-secondary fw-bold me-3">05</span>  
                                <span className="fw-bold">What certificates will I be received for this course?</span> 
                            </AccordionHeader>
                            <AccordionBody accordionId="faq5">
                                Certificate of completion from AF Academy and Gopal Dhawan Academy shall be awarded on completion of the course. An assessement test shall be conducted on completion of all the core segment modules.
                            </AccordionBody>
                          </AccordionItem>
                          {/* Item  */}
                          <AccordionItem>
                            <AccordionHeader targetId="faq6">
                                <span className="text-secondary fw-bold me-3">06</span>  
                                <span className="fw-bold">What certificates will I be received for this course?</span> 
                            </AccordionHeader>
                            <AccordionBody accordionId="faq5">
                                Certificate of completion from AF Academy and Gopal Dhawan Academy shall be awarded on completion of the course. An assessement test shall be conducted on completion of all the core segment modules.
                            </AccordionBody>
                          </AccordionItem>
                        </UncontrolledAccordion>
                        {/* Accordion END  */}
                      </TabPane>
                      {/* FAQ Content END  */}

                       {/* Fees Content START  */}
                       <TabPane className={classNames("fade", {show: activeTab==="course-pills-6", active: activeTab==="course-pills-6" })} tabId="course-pills-6">
                        <h5 className="mb-3">Fee Structure</h5>
                       <UncontrolledAccordion flush defaultOpen={`fee1`}>
                         {/* Item  */}
                         <AccordionItem>
                           <AccordionHeader targetId="fee1">
                               <span className="text-secondary fw-bold me-3">01</span>  
                               <span className="fw-bold">Government</span> 
                           </AccordionHeader>
                           <AccordionBody accordionId="fee1">
                              <div className="row bg-light p-2 rounded">
                                <div className="col-4"></div>
                                <div className="col-4 fw-bold">Indian Participants</div>
                                <div className="col-4 fw-bold">International Participants</div>
                               </div>
                               <div className="row">
                                <div className="col-4">Full Program<br/>(Core and Optional Segments)</div>
                                <div className="col-4">INR 10,000.00</div>
                                <div className="col-4">USD 200.00</div>
                               </div>
                               <hr/>
                               <div className="row">
                                <div className="col-4">Core Segment Only</div>
                                <div className="col-4">INR 6,000.00</div>
                                <div className="col-4">USD 120.00</div>
                               </div>
                               <hr/>
                               <div className="row">
                                <div className="col-4">Optional Lecture (each)</div>
                                <div className="col-4">INR 150.00</div>
                                <div className="col-4">USD 3.00</div>
                               </div>
                               <hr/>
                               <p><em>Before purchasing individual optional modules, the core segment must be purchased.</em><br/>
                               <em>GST will be charged extra as applicable. (present rate of GST is 18%)</em></p>
                           </AccordionBody>
                         </AccordionItem>

                         <AccordionItem>
                           <AccordionHeader targetId="fee2">
                               <span className="text-secondary fw-bold me-3">01</span>  
                               <span className="fw-bold">PSU, Boards, Private Companies</span> 
                           </AccordionHeader>
                           <AccordionBody accordionId="fee2">
                              <div className="row bg-light p-2 rounded">
                                <div className="col-4"></div>
                                <div className="col-4 fw-bold">Indian Participants</div>
                                <div className="col-4 fw-bold">International Participants</div>
                               </div>
                               <div className="row">
                                <div className="col-4">Full Program<br/>(Core and Optional Segments)</div>
                                <div className="col-4">INR 17,000.00</div>
                                <div className="col-4">USD 350.00</div>
                               </div>
                               <hr/>
                               <div className="row">
                                <div className="col-4">Core Segment Only</div>
                                <div className="col-4">INR 10,000.00</div>
                                <div className="col-4">USD 200.00</div>
                               </div>
                               <hr/>
                               <div className="row">
                                <div className="col-4">Optional Lecture (each)</div>
                                <div className="col-4">INR 250.00</div>
                                <div className="col-4">USD 5.00</div>
                               </div>
                               <hr/>
                               <p><em>Before purchasing individual optional modules, the core segment must be purchased.</em><br/>
                               <em>GST will be charged extra as applicable. (present rate of GST is 18%)</em></p>
                           </AccordionBody>
                         </AccordionItem>

                         <AccordionItem>
                           <AccordionHeader targetId="fee3">
                               <span className="text-secondary fw-bold me-3">01</span>  
                               <span className="fw-bold">Students/ Full time research scholars</span> 
                           </AccordionHeader>
                           <AccordionBody accordionId="fee3">
                              <div className="row bg-light p-2 rounded">
                                <div className="col-4"></div>
                                <div className="col-4 fw-bold">Indian Participants</div>
                                <div className="col-4 fw-bold">International Participants</div>
                               </div>
                               <div className="row">
                                <div className="col-4">Full Program<br/>(Core and Optional Segments)</div>
                                <div className="col-4">INR 7,000.00</div>
                                <div className="col-4">USD 140.00</div>
                               </div>
                               <hr/>
                               <div className="row">
                                <div className="col-4">Core Segment Only</div>
                                <div className="col-4">INR 4,000.00</div>
                                <div className="col-4">USD 80.00</div>
                               </div>
                               <hr/>
                               <div className="row">
                                <div className="col-4">Optional Lecture (each)</div>
                                <div className="col-4">INR 100.00</div>
                                <div className="col-4">USD 2.00</div>
                               </div>
                               <hr/>
                               <p><em>Before purchasing individual optional modules, the core segment must be purchased.</em><br/>
                               <em>GST will be charged extra as applicable. (present rate of GST is 18%)</em></p>
                           </AccordionBody>
                         </AccordionItem>
                       </UncontrolledAccordion>
                       {/* Accordion END  */}
                     </TabPane>
                     {/* Fees Content END  */}

                     {/* Register Content START  */}
                     <TabPane className={classNames("fade", {show: activeTab==="course-pills-7", active: activeTab==="course-pills-7" })} tabId="course-pills-7">
                      <h5 className="mb-3">Enroll in the course!</h5>
                      <p>Please provide us the details below to enroll in this course</p>
                      <EnrollmentForm cart={buyCourse()} />
                    </TabPane>

                    </TabContent>
                  </div>
                  {/* Tab contents END  */}
                </div>
              </div>
              {/* Main content END  */}

              {/* Right sidebar START  */}
              <div className="col-lg-4 pt-5 pt-lg-0">
                <div className="row mb-5 mb-lg-0">
                  <div className="col-md-6 col-lg-12">
                    {/* Video START  */}
                    <div className="card shadow p-2 mb-4 z-index-9">
                      <div className="overflow-hidden rounded-3">
                        {/*<StaticImage src="../assets/images/tunneling.jpg" width={390} alt="Tunnelling" />*/}
                        <StaticImage src="../assets/images/tunneling.jpg" className="card-img" alt="Tunnelling" />
                        <div className="bg-overlay bg-dark opacity-6"></div>
                        <div className="card-img-overlay d-flex align-items-start flex-column p-3">
                          <div className="m-auto">
                            <Button onClick={() => setPlayVideo(true)}  className="btn btn-lg text-danger btn-round btn-white-shadow mb-0">
                              <FontAwesomeIcon icon={faPlay} size='xl' />
                            </Button>
                            {/*<a href="https://cdn.hummz.app/WKDV0VWC3NskDpqcXNrV7cRZryubMoWYe8C1pDHMMwSeQxiZj-h_pon8VFEL/courses/tunnelling-course-intro-video-ilGQ2vgHjc.mp4" className="btn btn-lg text-danger btn-round btn-white-shadow mb-0">
                              <FontAwesomeIcon icon={faPlay} size='xl' />
                            </a>*/}
                          </div>
                        </div>
                      </div>
            
                      {/* Card body  */}
                      <div className="card-body px-3">
                        {/* Info  */}
                        <div className="d-flex justify-content-between align-items-center">
                          {/* Price and time  */}
                          <div>
                            <div className="d-flex align-items-center">
                              <h5 className="fw-bold mb-0 me-2"><small>From </small>INR 4000 / USD 80</h5>
                            </div>
                          </div>

                          {/* Share button with dropdown  */}
                          <UncontrolledDropdown>
                            {/* Share button  */}
                            <DropdownToggle className="rounded small btn-sm btn-light">
                                <FontAwesomeIcon icon={faShareAlt} />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-w-sm dropdown-menu-end min-w-auto shadow rounded">
                              <DropdownItem tag={`div`}><TwitterShareButton><TwitterIcon size={22} className="rounded" /> Twitter</TwitterShareButton> </DropdownItem>
                              <DropdownItem tag={`div`}><FacebookShareButton><FacebookIcon size={22} className="rounded" /> Facebook</FacebookShareButton> </DropdownItem>
                              <DropdownItem tag={`div`}><LinkedinShareButton><LinkedinIcon size={22} className="rounded" /> LinkedIn</LinkedinShareButton>  </DropdownItem>
                              <DropdownItem tag={`div`}><WhatsappShareButton><WhatsappIcon size={22} className="rounded" /> WhatsApp</WhatsappShareButton> </DropdownItem>
                              <DropdownItem tag={`div`}><TelegramShareButton><TelegramIcon size={22} className="rounded" /> Telegram</TelegramShareButton> </DropdownItem>
                              <DropdownItem tag={`div`}><EmailShareButton><EmailIcon size={22} className="rounded" /> Email</EmailShareButton> </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>

                        {/* Buttons  */}
                        <div className="mt-3 d-sm-flex justify-content-sm-between">
                          <Link to="/register" className="btn btn-success mb-0">Enroll in Course</Link>
                        </div>
                      </div>
                    </div>
                    {/* Video END  */}

                    <div className="col-md-6 col-lg-12">
                      <div className="card card-body shadow p-4 mb-4">
                        <h4 className="mb-3">Download</h4>
                        <ul className="list-group list-group-borderless">
                        <li className="list-group-item">
                          <a className="h6 fw-light mb-0 d-flex justify-content-between align-items-center" href={brochurePdf} target="_blank" rel="noreferrer">
                            <span><FontAwesomeIcon icon={faFilePdf} fixedWidth className="text-primary"/> Course Brochure</span>
                            <FontAwesomeIcon icon={faDownload} fixedWidth/>
                          </a>
                        </li>
                        <li className="list-group-item">
                            <a className="h6 fw-light mb-0 d-flex justify-content-between align-items-center" href={registerPdf} target="_blank" rel="noreferrer">
                            <span><FontAwesomeIcon icon={faFileAlt} fixedWidth className="text-primary" /> Offline Registration Form</span>
                            <FontAwesomeIcon icon={faDownload} fixedWidth/>
                          </a>
                        </li>
                        </ul>
                      </div>
                    </div>

                    {/* Course info START  */}
                    <div className="card card-body shadow p-4 mb-4">
                      {/* Title  */}
                      <h4 className="mb-3">This course includes</h4>
                      <ul className="list-group list-group-borderless">
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                          <span className="h6 fw-light mb-0"><FontAwesomeIcon icon={faBookOpen} fixedWidth className="text-primary"/> Lectures</span>
                          <span>100</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                          <span className="h6 fw-light mb-0"><FontAwesomeIcon icon={faVideo} fixedWidth className="text-primary" /> Videos</span>
                          <span>35+ hours</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                          <span className="h6 fw-light mb-0"><FontAwesomeIcon icon={faClock} fixedWidth className="text-primary" /> Duration</span>
                          <span>25 weeks</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                          <span className="h6 fw-light mb-0"><FontAwesomeIcon icon={faSignal} fixedWidth className="text-primary" /> Skill Level</span>
                          <span>Advanced</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                          <span className="h6 fw-light mb-0"><FontAwesomeIcon icon={faGlobe} fixedWidth className="text-primary" /> Language</span>
                          <span>English</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                          <span className="h6 fw-light mb-0"><FontAwesomeIcon icon={faUserClock} fixedWidth className="text-primary" /> Available from</span>
                          <span>June 2022</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                          <span className="h6 fw-light mb-0"><FontAwesomeIcon icon={faMedal} fixedWidth className="text-primary" /> Certificate</span>
                          <span>Yes</span>
                        </li>
                      </ul>
                    </div>
                    {/* Course info END  */}
                  </div>
                  {/*<div className="col-md-6 col-lg-12">
                    <div className="card card-body shadow p-4 mb-4">
                      <h4 className="mb-3">Contact Us</h4>
                      <ContactWidget />
                    </div>
                    </div>*/}

                </div>{/* Row End  */}
              </div>
              {/* Right sidebar END  */}

            </div>{/* Row END  */}
          </div>
        </section>
        {(typeof window !== 'undefined') && 
        <ModalVideo channel='custom' autoplay isOpen={playVideo} onClose={() => setPlayVideo(false)} url='https://cdn.hummz.app/WKDV0VWC3NskDpqcXNrV7cRZryubMoWYe8C1pDHMMwSeQxiZj-h_pon8VFEL/courses/tunnelling-course-intro-video-ilGQ2vgHjc.mp4' />
        }
        {/* ======================= Page content END */}
    </Layout>
  )
}

export default CourseDetail