import React, { useState } from "react"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'
import { Modal, ModalBody, ModalHeader } from "reactstrap"

const CourseInstructors = () => {
    const [instructorDetails, setInstructorDetails] = useState({})
    const [instructorAvatar, setInstructorAvatar] = useState({})
    const [showInstructor, setShowInstructor] = useState(false)
    const InstructorsData = useStaticQuery(graphql `
        query InstructorsQuery {
            allInstructorsJson {
                edges {
                  node {
                    id
                    name
                    description
                    avatar {
                      childImageSharp {
                        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                      }
                    }
                    profile
                  }
                }
              }
        }
    `)

    const showInstructorModal = (instructor) => {
        // console.log('instructor', instructor)
        const avatarImage = getImage(instructor.avatar)
        setInstructorAvatar(avatarImage)
        setInstructorDetails(instructor)
        setShowInstructor(true)
    }
    
    return (
        <div className="row row-eq-height g-0 align-top">
            <h5 className="mb-3">Course Instructors<br/><small>(names are in alphabetical order of surname)</small></h5>
            {InstructorsData.allInstructorsJson.edges.map((instructor) => {
                const avatarImage = getImage(instructor.node.avatar)
                return (
                    <div className="col-md-4 pt-2 shadow-hover d-flex flex-column align-items-center text-center" role='button' key={instructor.node.id} onClick={() => showInstructorModal(instructor.node)} tabIndex={0} onKeyDown={() => showInstructorModal(instructor.node)}>
                        <GatsbyImage image={avatarImage} alt={instructor.node.name || ' '} className="img-fluid rounded-circle border border-primary" />
                        <div className="card-body">
                            <h5 className="card-title mb-0 text-danger">{instructor.node.name}</h5>
                            <p className="mb-2">{instructor.node.description}</p>
                        </div>
                    </div>
                )
            })}
            <Modal centered fade backdrop scrollable size='lg' isOpen={showInstructor}>
                <ModalHeader toggle={() => setShowInstructor(!showInstructor)}>
                    <div className="d-flex flex-row align-items-center">
                        <GatsbyImage image={instructorAvatar} alt={instructorDetails.name || ' '} className="img-fluid rounded-circle border border-primary" style={{width: "80px"}} />
                        <div className="mx-2">
                            <h5 className="card-title mb-0 text-danger">{instructorDetails.name}</h5>
                            <small>{instructorDetails.description}</small>
                        </div>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <div dangerouslySetInnerHTML={{__html: instructorDetails.profile}} />
                </ModalBody>
            </Modal>
        </div>
    )
}

export default CourseInstructors