import * as React from "react"
import CourseDetail from "../components/CourseDetail"

// markup
const IndexPage = () => {
  return (
    <CourseDetail />
  )
}

export default IndexPage
