import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import { AccordionBody, AccordionHeader, AccordionItem, Button, UncontrolledAccordion } from "reactstrap"
import { faCirclePlay } from "@fortawesome/free-regular-svg-icons"
import ModalVideo from "react-modal-video"
import { createPortal } from "react-dom"

const VideoPortal = (props) => {
    return createPortal(
        <ModalVideo 
            {...props}
        />,
        document.body
    )
}

const CourseCurriculumSection = ({sections}) => {
    const [playVideo, setPlayVideo] = React.useState(false)
    const [currentSample, setCurrentSample] = React.useState(null)

    const playSample = (sample) => {
        setCurrentSample(sample)
        setPlayVideo(true)
    }

    return (
        <React.Fragment>
            {(typeof window !== 'undefined') && 
                <VideoPortal
                    channel='custom' 
                    autoplay 
                    isOpen={playVideo} 
                    onClose={() => setPlayVideo(false)} 
                    url={currentSample} 
                />
            }
            <UncontrolledAccordion flush>
                {sections.map((section) => (
                    <AccordionItem className="mb-3" key={`ai-${section.id}`}>
                        <AccordionHeader targetId={section.id} className="bg-light px-3">
                            <div className="fw-bold rounded d-sm-flex d-inline-block">
                                {section.name}
                                <span className="small ms-0 ms-sm-2">({section.items.length} Lectures)</span> 
                            </div>
                        </AccordionHeader>
                        <AccordionBody accordionId={section.id} className="mt-3">
                            {section.items.map((sectionItem, index) => (
                                <div  key={`si-${section.id}-${index}`}>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="position-relative d-flex align-items-center">
                                        <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
                                        <span className="d-inline-block ms-2 mb-0 h6 fw-light">{sectionItem.title}</span>
                                    </div>
                                    
                                    {sectionItem.sample 
                                        ? <Button onClick={() => playSample(sectionItem.sample)} className="text-danger btn-link btn-white-shadow p-0 mb-0"> <FontAwesomeIcon icon={faCirclePlay} size='xl' /> </Button>
                                        : <p className="mb-0">&nbsp;</p>
                                    }
                                </div>
                                <hr/>
                                </div>
                            ))}
                        </AccordionBody>
                    </AccordionItem>
                ))}
            </UncontrolledAccordion>
        </React.Fragment>
    )

}

export default CourseCurriculumSection