import React from "react"
import CurriculumData from "../data/CourseCurriculum.json"
import CourseCurriculumSection from "./CourseCurriculumSection"

const CourseCurriculum = () => {
    return (
        <div>
            {CurriculumData.map((category) => {
                return (
                    <div key={category.id} id={category.id}>
                        <h4>{category.name}</h4>
                        <CourseCurriculumSection sections={category.items} />
                    </div>
                )
            })}
        </div>
    )
}

export default CourseCurriculum